import styled, { css } from "styled-components";
import { TABLET_MAX_WIDTH, MOBILE_MIN_WIDTH } from "@constants";

export const OffersSectionContainer = styled.div`
    margin: ${({ hasTitle }) => !!hasTitle ? 0 : "7.5rem 0"};
    display: flex;
    flex-direction: row;

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        width: 100%;
        justify-content: center;
        flex-wrap: wrap;
        margin: 0;
    }
`;

export const OfferItem = styled.div`
    width: 50%;
    border: 0.0625rem solid #4a2b43;
    padding: 5rem 8.438rem 5rem 10.188rem;

    &:nth-child(2) {
        border-left: none;
        padding-left: 8.438rem;
        padding-right: 10.188rem;
    }

    @media only screen and (min-width: ${TABLET_MAX_WIDTH}px) {
        &:hover {
            background: rgba(196, 177, 128, 0.1);
            border-color: rgba(196, 177, 128, 0.6);

            & h2 {
                color: #c4b180;
            }

            & button {
                color: white;
            }
        }

        &:nth-child(2):hover {
            border-left: 0.0625rem solid rgba(196, 177, 128, 0.6);
        }
    }

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        width: 100%;
        border-right: none;
        padding: 5rem 6.25rem;

        &:nth-child(2) {
            border-top: none;
            padding: 5rem 6.25rem;
        }
    }

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        padding: 5rem 1.5626rem;

        &:nth-child(2) {
            padding: 5rem 1.5626rem;
        }
    }
`;
